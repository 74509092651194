<template>
  <div class="table">
    <v-data-table
      @click:row="onClickRow"
      v-bind="$attrs"
      v-on="$listeners"
      loader-height="2"
    >
      <template v-slot:item.createdAt="{ item }">
        <div>
          {{ formatDate(item.createdAt) }}
        </div>
      </template>
      <template v-slot:item.updatedAt="{ item }">
        <div>
          {{ formatDate(item.updatedAt) }}
        </div>
      </template>
      <template v-slot:item.deletedAt="{ item }">
        <div>
          {{ formatDate(item.deletedAt) }}
        </div>
      </template>
      <template v-slot:item.lastSeenAt="{ item }">
        <div>
          {{ formatDate(item.lastSeenAt) }}
        </div>
      </template>
      <template 
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
      >
        <slot :name="name" v-bind="slotData" />
      </template>
    </v-data-table>
    <table-details
      fixed
      width="365"
      right
      temporary
      v-model="showDrawer"
    >
      <slot name="details" />
    </table-details>
  </div>
</template>
<script>
import { formatDate } from '@/utils/functions'

import TableDetails from './details'

export default {
  name: 'Table',
  components: {
    TableDetails
  },
  data: () => ({
    showDrawer: false,
  }),
  methods: {
    onClickRow(row) {
      this.showDrawer = [ ...row.originalData ]
    },
    formatDate(date) {
      return formatDate(date)
    }
  }
}
</script>
<style lang="scss">

</style>