var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('v-data-table',_vm._g(_vm._b({attrs:{"loader-height":"2"},on:{"click:row":_vm.onClickRow},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt))+" ")])]}},{key:"item.deletedAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.deletedAt))+" ")])]}},{key:"item.lastSeenAt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.lastSeenAt))+" ")])]}},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners)),_c('table-details',{attrs:{"fixed":"","width":"365","right":"","temporary":""},model:{value:(_vm.showDrawer),callback:function ($$v) {_vm.showDrawer=$$v},expression:"showDrawer"}},[_vm._t("details")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }