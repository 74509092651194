<template>
  <card>
    <data-table
      :loading="inProcess"
      :items="items"
      :headers="headers"
      @click:row="onClickRow"
    >
      <template v-slot:item.avatar="{ item }">
        <div>
          <v-avatar
            size="36"
          >
            <v-img :src="item.avatar" />
          </v-avatar>
        </div>
      </template>
      <template 
        slot="details"
      >
        <div
          class="d-flex flex-column"
        >
          <profile
            v-if="userData"
            :user-data="userData"
          />
          <v-btn
            class="mt-4 mx-auto align-self-center"
            color="primary"
            v-if="userData"
            :to="`/dashboard/user/${userData[0]}`"
          >
            Go to page
          </v-btn>
          <timeline
            v-if="eventsLoaded"
            :timeline-data="events"
          />
        </div>
      </template>
    </data-table>
  </card>
</template>
<script>
import Card from '@/components/card'
import DataTable from '@/components/table'
import Profile from '@/components/profile'
import Timeline from '@/components/timeline'

import { getDocsFromCollection, getDocsFromCollectionByQuery } from '@/fb'
import { groupBy, getDeepKeys, getValueFromObjectByKey } from '@/utils/functions'

export default {
  name: 'DashboardUsersAll',
  components: {
    DataTable,
    Card,
    Profile,
    Timeline
  },
  data: () => ({
    eventsLoaded: false,
    userData: null,
    inProcess: false,
    excludeKeys: ['seconds', 'nanoseconds', 'info'],
    hiddenHeaders: [],
    headers: [],
    items: [],
    events: [],
    itemsYears: [],
    itemsByYears: {}
  }),
  async created() {
    const responseData = await this.fetchData()
    const deepKeys = getDeepKeys(responseData[0][1]).filter(key => {
      const splited = key.split('.')
      
      if (this.excludeKeys.includes(splited[splited.length - 1])) return null
      return key
    })
    const items = responseData.map(item => {
      const result = deepKeys.reduce((acc, key) => {
        const splited = key.split('.')
        acc[splited[splited.length - 1]] = getValueFromObjectByKey(item[1], key)
        return acc
      }, {
        originalData: item
      })

      return result
    })
    const clearKeys = deepKeys.map(key => {
      const splited = key.split('.')
      return splited[splited.length - 1]
    })
    const headers = clearKeys.map(key => ({ value: key, text: key }))

    this.headers = headers
    this.items = items
  },
  methods: {
    onClickRow(row) {
      this.userData = row.originalData
    },
    async fetchData() {
      this.inProcess = true
      const data = await getDocsFromCollection('customers')
      this.inProcess = false

      return data
    },
    groupByDate(to) {
      const result = groupBy(to, 'createdAt', true)
      return result
    }
  },
  watch: {
    async userData(to) {
      this.eventsLoaded = false
      if (!to) return
      const [ userId ] = to
      const data = await getDocsFromCollectionByQuery('events', {  
        where: {
          userId: {
            operator: '==',
            operand: userId
          }
        },
        orderBy: {
          createdAt: {
            direction: 'desc'
          }
        }
      })

      this.events = this.groupByDate(data)
      this.eventsLoaded = true
      
    }
  }
}
</script>