<template>
  <div class="table__details">
    <v-navigation-drawer
      v-bind="$attrs"
      style="top: 44px; height: calc(100% - 44px); overflow: hidden;"
    >
      <v-toolbar
        flat
        height="44"
      >
        <v-toolbar-title>Toolbar</v-toolbar-title>
        <v-spacer />
        <v-icon
          @click="$emit('input', false)"
        >
          mdi-close
        </v-icon>
      </v-toolbar>
      <v-divider />
      <div
        class="custom-scroll"
        style="height: calc(100% - 45px); overflow-y: auto;"
      >
        <slot />
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: 'TableDetails'
}
</script>
<style lang="scss">
.table__details {
  z-index: 7;
}
</style>